import { createMuiTheme } from '@material-ui/core/styles'

// Override default theme
// https://material-ui.com/customization/default-theme/?expand-path=$.palette.primary
const theme = createMuiTheme({
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      light: '#4482D0', // blue_3
      main: '#002E70', // blue_0
      dark: '#1463B1', // blue_2
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#C4E0F7', // blue_5
      main: '#7CAEE5', // blue_4
      dark: '#115190', // blue_1
      contrastText: '#ffffff',
    },
    error: {
      light: '#FFEEEE', // red_3
      main: '#EA7A7A', // red_2
      dark: '#D85B5B', // red_1
    },
    warning: {
      light: '#FFE183', // yellow-2
      main: '#FEA45A', // orange_2
      dark: '#EA872D', // orange_1
    },
    success: {
      light: '#D0E6A7', // green_4
      main: '#8DBA38', // orange_3
      dark: '#437124', // green_1
    },
    text: {
      primary: '#63656A',
      secondary: '#ffffff',
    },
    green: {
      light: '#D0E6A7',
      main: '#B2D66F',
    },
    blue: {
      light: '#F2F9FF',
    },
    gray: {
      light: '#F3F7FB',
      main: '#D9E1E9',
      dark: '#C9D1DA',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    body1: {
      color: '#63656A', // gray_2
    },
  },
  shadows: Array(25).fill('none'),
})

theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: 24,
      fontWeight: 700,
    },
    textPrimary: {
      color: theme.palette.primary.light,
    },
    containedPrimary: {
      '& .MuiButton-endIcon': {
        '& svg': {
          color: theme.palette.text.secondary,
        },
      },
      '&:disabled': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main + '80',
      },
    },
    outlinedPrimary: {
      '&:disabled': {
        color: theme.palette.primary.main + '80',
        borderColor: theme.palette.primary.main + '80',
      },
    },
    endIcon: {
      marginLeft: '0.8rem',
    },
    containedSizeLarge: {
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.25rem',
      },
    },
  },
  MuiTypography: {
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '1.375rem',
    },
    h4: {
      fontSize: '1.125rem',
    },
  },
  MuiPaper: {
    root: {
      boxShadow: 'none',
    },
  },
  MuiContainer: {
    root: {
      [theme.breakpoints.up('lg')]: {
        paddingRight: '4rem',
        paddingLeft: '4rem',
      },
    },
  },
  MuiRadio: {
    root: {
      color: theme.palette.gray.dark,
    },
  },
  MuiInput: {
    input: {
      color: theme.palette.secondary.dark,
    },
    underline: {
      '&:before': {
        borderBottom: '1px solid ' + theme.palette.secondary.light,
      },
      '&:hover': {
        'borderBottom': '1px solid ' + theme.palette.primary.light,
        '&:before': {
          borderBottom:
            '1px solid ' + theme.palette.primary.light + '!important',
        },
      },
      '&:after': {
        borderBottom: '1px solid ' + theme.palette.primary.light,
      },
    },
  },
  MuiAvatar: {
    colorDefault: {
      color: theme.palette.secondary.main,
    },
  },
  MuiInputAdornment: {
    positionStart: {
      '& p': {
        color: theme.palette.secondary.dark,
      },
    },
  },
  MuiDialog: {
    paper: {
      margin: 0,
      borderTopRightRadius: '1.6rem',
      borderTopLeftRadius: '1.6rem',
      borderBottomRightRadius: '0',
      borderBottomLeftRadius: '0',
      [theme.breakpoints.up('md')]: {
        borderRadius: '1rem',
      },
    },
    paperFullWidth: {
      width: '100%',
    },
    scrollPaper: {
      alignItems: 'flex-end',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
      },
    },
  },
  MuiDialogContent: {
    root: {
      'padding': 0,
      '&:first-child': {
        paddingTop: 0,
      },
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgb(68 130 208 / 85%)',
    },
  },
  MuiCheckbox: {
    root: {
      color: theme.palette.primary.light,
    },
    checked: {
      color: theme.palette.primary.light,
    },
    colorPrimary: {
      '&.Mui-checked ': {
        color: theme.palette.primary.light,
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    label: {
      'fontSize': '0.75rem',
      'paddingTop': '0.5625rem',
      '& strong': {
        color: theme.palette.secondary.main,
      },
      '& a': {
        color: theme.palette.secondary.main,
        fontWeight: 600,
        textDecoration: 'none',
      },
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: '1rem',
    },
  },
  MuiSvgIcon: {
    root: {
      color: theme.palette.primary.light,
    },
  },
  MuiSelect: {
    icon: {
      color: theme.palette.primary.light,
    },
  },
  MuiAccordion: {
    rounded: {
      'borderRadius': '0.5rem',
      '&:first-child, &:last-child': {
        borderTopRightRadius: '0.5rem',
        borderTopLeftRadius: '0.5rem',
        borderBottomLeftRadius: '0.5rem',
        borderBottomRightRadius: '0.5rem',
      },
    },
    root: {
      '&$expanded': {
        marginTop: 0,
      },
    },
  },
  MuiFormGroup: {
    root: {
      '& .$MuiFormControlLabel-root': {
        '& .$MuiFormControlLabel-label': {
          fontSize: '1rem',
          fontWeight: 600,
          color: theme.palette.primary.light,
        },
      },
    },
  },
}

export default theme
