import { observable, action, decorate, computed } from 'mobx'
import { ignore } from 'mobx-sync'
import _ from 'lodash'
// Persist

class SimulatorStore {
  currentStep = 1
  debtsData = []
  currentInstitution = {}

  addDebt = (debt) => {
    this.debtsData.push(debt)
  }

  removeDebt = (index) => {
    this.debtsData.splice(index, 1)
  }

  removeLastAddedDebt = () => {
    this.debtsData.pop()
  }

  setSelection = (institution) => (this.currentInstitution = { ...institution })

  removeSelection = () => (this.currentInstitution = {})

  get debtsTotal() {
    return _.reduce(
      this.debtsData,
      function (accumulated, debt) {
        return accumulated + parseFloat(debt.debtAmount)
      },
      0
    )
  }

  setStep = (step) => (this.currentStep = step)
}

decorate(SimulatorStore, {
  debtsData: observable,
  currentInstitution: observable,
  currentStep: [observable, ignore],
  debtsTotal: computed,
  addDebt: action,
  removeDebt: action,
  removeLastAddedDebt: action,
  setSelection: action,
  removeSelection: action,
  setStep: action,
})

export default new SimulatorStore()
