import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// Custom components
import Loader from 'components/molecules/loader'
import ScrollToTop from 'components/molecules/scrollTop'
// Pages
const Landing = lazy(() => import('./landing'))
const Simulator = lazy(() => import('./simulator'))
// Register
const Register = lazy(() => import('./register'))
const OTPRequest = lazy(() => import('./register/otp/'))
const OTPValidate = lazy(() => import('./register/otp/validate'))
// Documents
const Documents = lazy(() => import('./register/documents'))
const UploadDocuments = lazy(() => import('./register/documents/upload'))
const DocumentsDetails = lazy(() => import('./register/documents/details'))
const DocumentsIdentity = lazy(() => import('./register/documents/identity'))
const Beneficiary = lazy(() => import('./register/beneficiary'))
const Finance = lazy(() => import('./register/finance'))
const Bureau = lazy(() => import('./register/bureau'))
const BureauScore = lazy(() => import('./register/bureau/score'))
const Debts = lazy(() => import('./register/debts'))
const Plan = lazy(() => import('./register/plan'))
const Resume = lazy(() => import('./register/resume'))
const Contract = lazy(() => import('./register/contract'))
const Payment = lazy(() => import('./register/payment'))
const PaymentCash = lazy(() => import('./register/payment/cash'))
const PaymentCard = lazy(() => import('./register/payment/card'))
const PaymentSuccess = lazy(() => import('./register/payment/success'))

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path='/' component={Landing} />
          <Route exact path='/simulador' component={Simulator} />
          <Route exact path='/registro/' component={Register} />
          <Route exact path='/registro/validar' component={OTPRequest} />
          <Route exact path='/registro/autorizacion' component={OTPValidate} />
          <Route exact path='/registro/documentacion' component={Documents} />
          <Route
            exact
            path='/registro/documentacion/subir'
            component={UploadDocuments}
          />
          <Route
            exact
            path='/registro/documentacion/completar'
            component={DocumentsDetails}
          />
          <Route
            exact
            path='/registro/documentacion/identidad/'
            component={DocumentsIdentity}
          />
          <Route exact path='/registro/beneficiario/' component={Beneficiary} />
          <Route exact path='/registro/finanzas/' component={Finance} />
          <Route exact path='/registro/buro/' component={Bureau} />
          <Route exact path='/registro/buro/score' component={BureauScore} />
          <Route exact path='/registro/deudas' component={Debts} />
          <Route exact path='/registro/plan' component={Plan} />
          <Route exact path='/registro/resumen' component={Resume} />
          <Route exact path='/registro/contrato' component={Contract} />
          <Route exact path='/registro/pago' component={Payment} />
          <Route exact path='/registro/pago/efectivo' component={PaymentCash} />
          <Route exact path='/registro/pago/tarjeta' component={PaymentCard} />
          <Route exact path='/registro/pago/exito' component={PaymentSuccess} />
        </Switch>
      </Router>
    </Suspense>
  )
}

export default AppRoutes
