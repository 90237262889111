import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'mobx-react'
import { AsyncTrunk } from 'mobx-sync'
import 'mobx-react-lite/batchingForReactDom'

import Routes from 'components/pages/Routes'
import store from 'stores/'
import theme from 'theme/'

import * as serviceWorker from './serviceWorker'

const trunk = new AsyncTrunk(store, { storage: localStorage })

trunk.init().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider {...store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
