import { observable, action, decorate } from 'mobx'
import { ignore } from 'mobx-sync'
// Services
import Validation from 'services/documentsValdation'

const docsValidation = new Validation()

// Persist
class RegisterStore {
  loading = false
  otpValidated = false
  setOtpValidated = (validated) => (this.otpValidated = validated)

  matiData = {}

  validateDocuments = async (frontImage, backImage, documentType) => {
    this.loading = true
    await docsValidation
      .postDocuments(frontImage, backImage, documentType)
      .then((response) => {
        const { data } = response.data
        console.log('z->', data)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        this.loading = false
      })
  }
}

decorate(RegisterStore, {
  loading: [observable, ignore],
  otpValidated: [observable, ignore],
  setOtpValidated: action,
  matiData: observable,
  validateDocuments: action,
})

export default new RegisterStore()
