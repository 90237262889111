import Api from 'api'

const BASE_URL = 'https://7cb74d90503e.ngrok.io'

class Validation extends Api {
  postDocuments = (frontImage, backImage, documentType) => {
    const formData = new FormData()
    const json = {
      type: 'national-id',
      country: 'MX',
      front: frontImage,
      back: backImage,
    }

    Object.keys(json).forEach((key) => {
      formData.append(key, json[key])
    })

    const options = {
      method: 'POST',
      url: `${BASE_URL}/v1/mati-verification`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }

    return this.request(options)
  }
}

export default Validation
